import React, { useRef } from 'react';

import SEO from '../components/seo';
import { Link, graphql } from 'gatsby';
import { Layout } from '../layout/Layout';
import { BannerWithBackground as Banner, Description } from '../components/common';
import ContactBanner from '../components/modules/capacitaciones/ContactBanner';
import { useQueryParam } from 'gatsby-query-params';
import useDragScroll from 'use-drag-scroll';
import { clamp } from '../utils/helpers';

export const query = graphql`
  query CapacitacionesQuery {
    site {
      siteMetadata {
        cmsUrl
      }
    }
  }
`;

const Capacitaciones = ({ data, pageContext }) => {
  const { banner_image_mobile, banner_image, content } = pageContext.data;
  const { banner_title, banner_text, bottom_banner_highlight, bottom_banner_text, bottom_banner_button } =
    pageContext.translation;

  const contentTranslation = content
    .map(element => ({
      id: element.id,
      image: element.image,
      ...element.translations.filter(t => t.languages_code.code === pageContext.languageCode)[0],
    }))
    .filter(e => e.title && e.description);

  let contentIndex = useQueryParam('tab', 1);
  contentIndex = parseInt(contentIndex) || 1
  contentIndex = clamp(contentIndex, 1, contentTranslation.length)
  const pageContent = contentTranslation[contentIndex - 1];
  const cmsURL = data.site.siteMetadata.cmsUrl;

  // Scroll on tabs
  const selectSliderRef = useRef();
  useDragScroll({
    sliderRef: selectSliderRef,
  });

  return (
    <Layout pageId="capacitaciones" currentLanguage={pageContext.languageCode}>
      <Banner
        title={banner_title}
        description={banner_text}
        desktopImage={`${cmsURL}/assets/${banner_image.id}`}
        mobileImage={`${cmsURL}/assets/${banner_image_mobile.id}`}
      />

      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div ref={selectSliderRef} className="select-slider no-scrollbar">
              {contentTranslation.map((pageInfo, index) => {
                return (
                  <Link
                    key={pageInfo.id}
                    className={`btn ${contentIndex === index + 1 ? 'active' : ''}`}
                    to={`?tab=${index + 1}`}
                  >
                    {pageInfo.short_title || pageInfo.title}
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Description
        heading={pageContent.title}
        body={pageContent.description}
        image={pageContent.image ? `${cmsURL}/assets/${pageContent.image?.id}` : null}
      />

      <ContactBanner
        highlight={bottom_banner_highlight}
        text={bottom_banner_text}
        button={bottom_banner_button}
        to={`${pageContext.basePath}/contact`}
        linkState={{ subject: 'Capacitaciones' }}
      />
    </Layout>
  );
};

export default Capacitaciones;

export const Head = () => <SEO title="Training - MicroRate" />;
