import React from 'react';

import { Link } from 'gatsby';

const ContactBanner = ({ highlight, text, button, to, linkState }) => {
  return (
    <section className="contact-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="contact-banner__container">
              <h2>
                <strong>{highlight}</strong>
              </h2>
              <p>{text}</p>
              <Link to={to} state={linkState} className="btn primary-btn">
                {button}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactBanner;
